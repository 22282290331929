import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import { listMulti } from "@/api/global";
import { UnknownObject } from "@/interface/common";

export interface DictOption {
  lookupCode: string;
  lookupValue: string;
}

export interface AllOptions {
  [prop: string]: { label: string; value: string | number }[];
}

export interface AllMap {
  [prop: string]: UnknownObject;
}

export interface DictState {
  allMap: AllMap;
  allOptions: AllOptions;
}

@Module({ dynamic: true, store, name: "dict" })
export class Dict extends VuexModule implements DictState {
  initializedNames = new Map();
  allMap: AllMap = {};
  allOptions: AllOptions = {};
  modelMap: { [key: string]: string } = {};

  @Mutation
  setMap({ name, dictOptions }: { name: string; dictOptions: DictOption[] }) {
    this.allOptions = {
      ...this.allOptions,
      [name]: dictOptions.map(({ lookupCode, lookupValue }) => {
        return {
          label: lookupValue,
          value: lookupCode
        };
      })
    };
    const nameMap: UnknownObject = {};
    dictOptions.forEach(({ lookupCode, lookupValue }) => {
      nameMap[lookupCode] = lookupValue;
    });
    this.allMap = {
      ...this.allMap,
      [name]: nameMap
    };
  }

  @Action
  async getDictMap(name: string) {
    if (!this.initializedNames.has(name)) {
      const requestPromise = listMulti({
        lookupTypes: name
      });
      this.initializedNames.set(name, requestPromise);
      const data = await requestPromise;
      if (data) {
        this.setMap({ name, dictOptions: data[name] });
        this.initializedNames.set(name, "");
      } else {
        this.initializedNames.delete(name);
      }
    } else {
      const requestPromise = this.initializedNames.get(name);
      if (requestPromise) {
        await requestPromise;
      }
    }
  }

  @Mutation
  setModelMap(data: []) {
    const modelMap: { [key: string]: string } = {};
    data.forEach(
      ({ modelId, modelName }: { modelId: string; modelName: string }) => {
        modelMap[modelId] = modelName;
      }
    );
    this.modelMap = modelMap;
  }
}

export const DictModule = getModule(Dict);
