import { Vue, Component } from "vue-property-decorator";
import { DictModule } from "@/store/modules/dict";
import dayjs from "dayjs";

@Component
export default class Dict extends Vue {
  async getDictMap(name: string) {
    if (DictModule.allMap[name]) {
      return true;
    } else {
      await DictModule.getDictMap(name);
      return true;
    }
  }

  getDictValue(name: string, key: string) {
    if (DictModule.allMap[name]) {
      return DictModule.allMap[name][key] || "/";
    } else {
      DictModule.getDictMap(name);
      return "/";
    }
  }

  getDictCode(name: string, key: string) {
    if (DictModule.allMap[name]) {
      const options = DictModule.allOptions[name];
      const result = options.find(({ label }) => {
        return key === label;
      });
      if (result) {
        return result.value;
      } else {
        return null;
      }
    } else {
      DictModule.getDictMap(name);
      return "/";
    }
  }

  async getDictOptions(name: string) {
    if (!DictModule.allOptions[name]) {
      await DictModule.getDictMap(name);
    }
    return DictModule.allOptions[name];
  }

  getFormatTime(time: string | number) {
    if (time) {
      return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return "/";
    }
  }

  getOneWeekValue() {
    const now = dayjs();
    return [
      now.subtract(7, "day").format("YYYY-MM-DD 00:00:00"),
      now.format("YYYY-MM-DD 23:59:59")
    ];
  }

  getOneMonthValue() {
    const now = dayjs();
    return [
      now.subtract(30, "day").format("YYYY-MM-DD 00:00:00"),
      now.format("YYYY-MM-DD 23:59:59")
    ];
  }

  getQuerydayValue(queryDay: number) {
    return Array.from({ length: queryDay }, (v, k) => {
      if (k === queryDay - 1) {
        return dayjs().format("YYYY-MM-DD");
      } else {
        return dayjs()
          .subtract(queryDay - 1 - k, "day")
          .format("YYYY-MM-DD");
      }
    });
  }

  getSeriesValue(
    seriesName: string,
    seriesType: string,
    result: any,
    xAxis: any,
    key: string,
    config?: object
  ) {
    return {
      name: seriesName,
      type: seriesType,
      data: xAxis.map((d: any) => {
        const value = result.find(({ dateTime }: { dateTime: string }) => {
          return dateTime === d;
        });
        if (value) {
          return value[key];
        } else {
          return 0;
        }
      }),
      ...config
    };
  }
}
