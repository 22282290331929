





























































































































































import { Vue, Component, Mixins } from "vue-property-decorator";
import {
  getUserAppData,
  getAppData,
  getUserByHeader,
  getServerInfo,
  addAppDataByUser,
  updateAppDataByUser,
  delAppDataByUser,
  findUserWatermarkByUserId
} from "@/api/global";
import { UserModule } from "@/store/modules/user";
import { getHostName } from "@/utils/common";
import dict from "@/mixins/dict";
@Component({
  name: "EnterPage"
})
export default class EnterPage extends Mixins(dict) {
  title = "添加常用应用";
  userName = "";
  userAppData: object[] = [];
  appData = {};
  appResData = {};
  cols = {};
  dataForm: any = {};
  visible = false;
  currentItem: any = {};
  serverVersion = "v2.0";
  apiVersion = "v1.0";
  days = 0;
  isDragable = true;
  platformTitle = '智慧城市微场景管理平台';
  logoUrl = require('@/assets/images/enter/img_logo.png');
  status = false

  defaultItem = {
    icon: require("@/assets/images/enter/img_list_default.png"),
    png: require("@/assets/images/enter/list_default.png")
  };

  get isShowAdd() {
    return this.userAppData.length < 8;
  }

  created() {
    this.UserWatermark();  // 获取配置logo
    this.getConstantApp(); // 获取常用应用
    this.getData(); // 获取分类应用
    this.getUserInfo();
    this.getServerInfo();
  }

  // 获取常用应用
  async getConstantApp() {
    const res = await getUserAppData();
    if (res) {
      let { defaultItem } = this;
      const result: object[] = [];
      const categoryOrders = res.map((item: { categoryOrder: number }) => {
        return item.categoryOrder;
      });
      const maxOrder = Math.max(...categoryOrders);
      const categoryLength = res.length;
      if (categoryLength < maxOrder) {
        const promises: any[] = [];
        res.forEach((item: any, index: number) => {
          promises.push(
            updateAppDataByUser({
              linkId: item.linkId,
              order: index + 1
            })
          );
        });
        await Promise.all(promises);
      }
      res.forEach(
        (item: {
          isValid: string;
          linkDescribe: string;
          linkIcon: string;
          linkId: string;
          linkName: string;
          linkUrl: string;
          categoryOrder: number;
        }) => {
          let {
            isValid,
            linkId,
            linkDescribe,
            linkIcon,
            linkName,
            linkUrl,
            categoryOrder
          } = item;
          if (isValid === "Y") {
            result.push({
              linkId,
              linkDescribe,
              linkIcon,
              linkName,
              linkUrl,
              categoryOrder,
              isConstant: true
            });
          } else {
            result.push({
              linkId,
              linkDescribe,
              linkIcon: defaultItem.icon,
              linkName,
              linkUrl,
              categoryOrder,
              isConstant: true
            });
          }
        }
      );
      this.userAppData = result;
    }
  }

  // 获取分类应用
  async getData() {
    const res = await getAppData();
    if (res) {
      let { defaultItem } = this;
      const data: any = res;
      const resObj: any = {};
      Object.keys(data).forEach((key: string) => {
        let catalogList = data[key];
        let arr: object[] = [];
        catalogList.forEach(
          (item: {
            isValid: string;
            linkDescribe: string;
            linkIcon: string;
            linkId: string;
            linkName: string;
            linkUrl: string;
          }) => {
            let {
              isValid,
              linkId,
              linkDescribe,
              linkIcon,
              linkName,
              linkUrl
            } = item;
            if (isValid === "Y") {
              arr.push({
                linkId,
                linkDescribe,
                linkIcon,
                linkName,
                linkUrl
              });
            } else {
              arr.push({
                linkId,
                linkDescribe,
                linkIcon: defaultItem.icon,
                linkName,
                linkUrl
              });
            }
          }
        );
        resObj[key] = arr;
      });
      this.appResData = resObj;
    }
  }

  async getUserInfo() {
    const data = await getUserByHeader();
    if (data) {
      this.userName = data.userName;
    }
  }
  async UserWatermark() {
    const data = await findUserWatermarkByUserId({})
    if (data) {
      const { platformTitle,logoUrl } = data
      this.platformTitle = platformTitle ? platformTitle : '智慧城市微场景管理平台'
      this.logoUrl = logoUrl ? logoUrl : require('@/assets/images/enter/img_logo.png')
      this.status = true
    }
  }

  checkSys() {
    this.visible = true;
  }

  closeD() {
    this.visible = false;
  }

  async getServerInfo() {
    const data = await getServerInfo();
    const { dataForm } = this;
    if (data) {
      dataForm.serverVersion = data.serverVersion;
      dataForm.apiVersion = data.apiVersion;
      dataForm.days = data.days;
      this.dataForm = dataForm;
    }
  }

  drag($event: any, target: any) {
    $event.dataTransfer.effectAllowed = "move";
    this.currentItem = target;
  }

  // 拖拽完成事件
  async drop(event: any) {
    if (!this.isDragable) {
      return false;
    }
    this.isDragable = false;
    event.dataTransfer.dropEffect = "move";
    let { currentItem } = this;
    if (currentItem.isConstant) {
      this.$message({
        type: "error",
        message: "不可添加已有常见应用"
      });
      this.isDragable = true;
      return false;
    }
    if (
      event.target.id == "add" ||
      event.target.classList[0] == "el-icon-plus"
    ) {
      // 如果是新增节点则调用新增常见入口接口
      const data: any[] = this.userAppData;
      let isvalid = true;
      data.forEach((item: { linkId: string }) => {
        const { linkId } = item;
        if (linkId == (currentItem as any).linkId) {
          return (isvalid = false);
        }
      });
      if (!isvalid) {
        this.$message({
          type: "error",
          message: "不可添加重复应用"
        });
        this.isDragable = true;
        return false;
      }
      const { linkId } = currentItem;
      const order = this.userAppData.length + 1;
      const res = await addAppDataByUser({
        linkId,
        order
      });
      if (res) {
        this.getConstantApp();
      }
      this.isDragable = true;
    }
  }

  async dropNode(event: any, node: any) {
    let { currentItem } = this;
    if (!currentItem.isConstant) {
      return false;
    }
    await Promise.all([
      updateAppDataByUser({
        linkId: currentItem.linkId,
        order: node.categoryOrder
      }),
      updateAppDataByUser({
        linkId: node.linkId,
        order: currentItem.categoryOrder
      })
    ]).then(() => {
      this.getConstantApp();
    });
  }

  // 删除
  delAppHandle(item: any) {
    this.$confirm("此操作会删除当前常用应用，是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    })
      .then(async () => {
        const { linkId } = item;
        const data = await delAppDataByUser([linkId]);
        if (data) {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.getConstantApp();
        }
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  enter({ linkUrl }: { linkUrl: string }) {
    const str = "Webshell:";
    const str1 = "EasyPrint:";
    if (linkUrl.indexOf(str) !== -1 || linkUrl.indexOf(str1) !== -1) {
      window.location.href = linkUrl;
      return;
    }
    if (/^http/.test(linkUrl)) {
      // http页面则直接跳转
      window.open(linkUrl);
    } else {
      // 将多余的/去掉 后台转发路径后面需要加斜杠
      linkUrl = linkUrl.replace(/\//g, "");
      if (process.env.NODE_ENV === "development") {
        window.open(`${process.env.VUE_APP_API}/${linkUrl}/`);
      } else {
        // 如果是线上，则直接跳到公用登录页
        window.open(`${getHostName()}/${linkUrl}/`);
      }
    }
  }

  logout() {
    UserModule.confirmLogout();
  }
}
